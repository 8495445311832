import React, { useContext } from 'react';
import styled from 'styled-components';
import Overlay from './Overlay';
import { UIContext } from '../components/UIContext';


const StyledMain = styled.main`
  grid-area: content;
  min-height: 80vh;
  display: grid;
  display: -ms-grid;

  /* grid-template-columns: 1fr minmax(50px, 700px) 1fr; */
  grid-template-columns: ${props =>
    props.maxWidth ? `1fr minmax(50px, ${props.maxWidth}px) 1fr` : "1fr minmax(300px, 700px) 1fr"};
  -ms-grid-columns: ${props =>
    props.maxWidth ? `1fr minmax(50px, ${props.maxWidth}px) 1fr` : "1fr minmax(300px, 700px) 1fr"};
  grid-template-areas: "left middle right";

  .middle {
    grid-column: 3 / 11;
    grid-area: middle;

    /* edge fix */
    -ms-grid-column: 2;
  }

  /* div.gatsby-image-wrapper {
    margin-bottom: 1rem;
  } */

  .article p a,
  .article ul > li > a,
  .article ol > li > a {
    text-decoration: underline;
    text-decoration-color: var(--foregrounddark);
    &:hover {
      background: rgba(0, 0, 0, 0.07);
    }
  }

  .article ul,
  .article ol {
    font-family: -apple-system, Arial, 'Helvetica', 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', sans-serif;
    font-size: 1.3rem;
    line-height: 1.6;
    margin-bottom: 1rem;
  }

  ul li,
  ol li {
    margin-bottom: 1rem;
  }

  code {
    padding: 0rem 0.5rem;
    background-color: var(--shadow);
    border-radius: 3px;
  }
    /* table styling */

  table {
    margin: 1rem auto;
  }

  table,
  th,
  td {
    border: 1px solid var(--foregrounddark);
    border-collapse: collapse;
  }

  th {
    padding: 0.5rem 0.8rem;
    text-align: left;
  }

  td {
    padding: 0.4rem 0.8rem;
    text-align: left;
  }

  

    thead tr>th{
      background: var(--secondarylight);
    }

    tbody tr>td:first-child {
    background: var(--foreground);
    text-align: left;
    }

    td:not(first-child) {
      text-align: center;
    }

  pre > div.prism-code {
    margin: 0 auto 0 auto;
  }

  figure figcaption {
    background: rgba(0, 0, 0, 0.06);
    font-style: italic;
    font-size: smaller;
    padding: 0.25rem 0.5rem;
    margin-bottom: 1rem;
  }

  /* custom contact form */
  div.middle.article>.form-wrapper {
    margin: 0 auto;
  }

  @media all and (max-width: 700px) {
    grid-template-columns: minmax(300px, 700px);
    -ms-grid-columns: minmax(300px, 700px);
    grid-template-areas: "middle";

    .middle > * {
      padding: 0rem 1.5rem 1rem 1.5rem;
    }

    .middle > section.infobox, section.featurebox  {
      padding: 1rem;
    }

    .middle > section.profile-card {
      padding: 1rem;
      margin: 1.5rem;
    }

    .middle > div.overlay,
    .middle video,
    div.middle > a:nth-child(2),
    .middle div.youtube-container,
    .middle > figure.codesnippet,
    .middle > div.imagecontainer,
    .middle > figure.imagecontainer,
    .middle > div.article-img,
    .middle > div.gatsby-image-wrapper,
    .middle > section.skipper-container {
      padding: 0rem 0rem;
    }
  }

  @media all and (max-width: 400px) {
    .breadcrumblevel:nth-child(1) {
      display: none;
    }
    .breadcrumblevel:nth-child(2) {
      white-space: nowrap;
    }
    table {
      font-size: 0.7rem;
    }
  }
`;

const MainContent = ({ maxWidth, article, children }) => {

  const uicontext = useContext(UIContext) // Destructured for direct access

  return (
    <StyledMain
      role="main"
      maxWidth={maxWidth ? maxWidth : "700"}
      hamburgerToggled={uicontext.hamburgerToggled}
    >
      <div className={article ? "middle article" : "middle"}>
        <Overlay
          hamburgerToggled={uicontext.hamburgerToggled}
          toggleHamburger={uicontext.toggleHamburger}
          overlay={uicontext.overlayToggled}
        />
        {children}
      </div>
    </StyledMain>
  );
}

export default React.memo(MainContent);