import React from "react";
import styled from "styled-components";

export const headingSizes = {
  h1: 4.5,
  h2: 2,
  h3: 1.3,
  h4: 0.8,
  h5: 0.8,
  h6: 0.8
};

const HStyles = styled.h1`
  font-size: ${headingSizes.h1}rem;
  font-size: ${({ as }) => as && `calc(16px + ${headingSizes[as]}vmin)`};
  margin: 6vmin 0rem 2vmin 0rem;
  position: relative;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);

  :hover .anchor {
    transform: translate(-120%, -30%);
    opacity: 1;
  }

  .anchor {
    position: absolute;
    transform: translate(-140%, -30%);
    text-decoration: none;
    opacity: 0;
    transition: all 0.1s;
  }
`;

const formatId = string =>
  string
    .replace(/\s<[^>]*>/gm, "")
    .replace(/\s+/g, "-")
    .replace(/(?=\W+)(?=[^-]+).*$/gi, "")
    .toLowerCase();

export default function H(props) {
  return (
    <HStyles {...props} id={formatId(props.children.toString())}>
      <a
        href={`#${formatId(props.children.toString())}`}
        aria-label={props.children}
        className="anchor"
        title="Right click to copy link to paragraph"
      >
        <svg
          width="20"
          height="20"
          aria-hidden="true"
          version="1.1"
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            fillRule="evenodd"
          />
        </svg>
      </a>
      {props.children}
    </HStyles>
  );
}
