import React, { useState, useContext } from 'react';
import { UIContext } from './UIContext';
import styled from "styled-components";
import * as emailjs from "emailjs-com";
import { details } from "../../details";


const StyledFormWrapper = styled.div`
  display: flex;
  max-width: 400px;
  min-width: 250px;

  @media all and (max-width: 600px) {
    margin: 5vmin auto;
  }

  form {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  textarea {
    font-family: inherit;
    min-height: 10rem;
    padding: 10px 10px 10px 10px;
  }

  button,
  textarea,
  input {
    margin: 0rem 0rem 0.5rem 0rem;
    padding: 10px 10px 10px 10px;

    border-radius: 5px;
    outline: none;
    border: 1px solid var(--foreground);
    color: var(--copy);
  }

  input:invalid,
  textarea:invalid {
    border: 1px solid var(--primarylight);
  }

  input::placeholder,
  .textarea::placeholder {
    font-family: Arial;
    font-size: 1rem;
    font-weight: lighter;
    color: var(--foreground);
    overflow: visible;
  }

  .error {
    margin: 0rem 0rem 0.5rem 0rem;
    text-align: center;
    font-size: 12px;
    color: var(--primarydark);
  }

  .response {
    margin: 0rem 0rem 0.5rem 0rem;
    text-align: center;
    font-size: 12px;
    color: var(--foreground);
  }

  button {
    background: var(--primary);
    color: var(--background);
    font-size: inherit;
    &:active {
      background: var(--primarydark);
    }
  }
`;

const validateEmail = email => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const content = {
  emailPlaceholder: {
    en: "Email",
    de: "Email",
    se: "E-post",
    da: "E-mail",
    no: "E-post",
    nl: "E-mail"
  },
  emailTitle: {
    en: "for example anna@email.com",
    de: "z.B. anna@email.com",
    se: "t.ex. anna@email.com",
    da: "f.eks. anna@email.com",
    no: "f.eks. anna@email.com",
    nl: "bijvoorbeeld anna@email.com"
  },
  websitePlaceholder: {
    en: "Your website URL",
    de: "Deine Webadresse",
    se: "Din webbplats URL",
    da: "Din hjemmeside URL",
    no: "Din nettside URL",
    nl: "Jouw website URL"
  },
  websiteTitle: {
    en: "for example 'www.mywebsite.com'",
    de: "z.B. www.meineseite.de",
    se: "t.ex. 'www.minhemsida.com'",
    da: "f.eks. 'www.minhjemmeside.com'",
    no: "f.eks. 'www.minhjemmeside.com'",
    nl: "bijvoorbeeld 'www.mijnwebsite.com'"
  },
  messagePlaceholder: {
    en: "Message",
    de: "Nachricht",
    se: "Meddelande",
    da: "Besked",
    no: "Melding",
    nl: "Bericht"
  },
  buttonText: {
    en: "Send",
    de: "Senden",
    se: "Skicka",
    da: "Send",
    no: "Send",
    nl: "Verzenden"
  }
};


const ContactForm = (props) => {
  const { formName, showHeadline, autoFocus } = props;
  const { language } = useContext(UIContext);

  const [formData, setFormData] = useState({
    email: null,
    webaddress: null,
    message: null,
  });

  const [errors, setErrors] = useState({
    email: null,
    response: null,
  });

  // Add a loading state
  const [isLoading, setIsLoading] = useState(false);

  const sendMail = (form, email, webaddress, message) => {
    // Before sending mail, set isLoading to true
    setIsLoading(true);

    emailjs
      .send(
        "default_service",
        "general-contact",
        {
          form,
          email,
          webaddress,
          message
        },
        details.mailjsuser
      )
      .then(
        response => {
          // push to data layer
          window.dataLayer = window.dataLayer || [];
          dataLayer.push({
            "event": "track-event",
            "eventCategory": "contact form",
            "eventAction": props.formName.toLowerCase(),
            "eventLabel": "success",
            "eventValue": "100",
            "eventNoninteraction": "false"

          });
          // success resp
          setErrors(errors => ({
            ...errors,
            response: "Message sent ✓"
          }));

          // After mail sent, set isLoading to false
          setIsLoading(false);
        },
        err => {

          // push to data layer
          dataLayer.push({
            "event": "track-event",
            "eventCategory": "contact form submission error",
            "eventAction": props.formName.toLowerCase(), // Fixed here
            "eventLabel": "success",
            "eventValue": "100",
            "eventNoninteraction": "false"
          });

          // error rsp
          setErrors(errors => ({
            ...errors,
            response: `Error ${err}`
          }));

          // On error, also set isLoading to false
          setIsLoading(false);
        }
      );
  };

  const handleChange = event => {
    const { name, value } = event.target;

    if (name === "email") {
      setErrors({ ...errors, email: !validateEmail(value) });
    }

    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (!errors.email) {
      sendMail(formName, formData.email, formData.webaddress, formData.message);
      event.target.reset();
    }
  };

  return (
    <StyledFormWrapper className="form-wrapper">
      <form onSubmit={handleSubmit}>
        {showHeadline ? <h2>Contact</h2> : ""}
        <input
          type="email"
          name="email"
          onChange={handleChange}
          noValidate
          autoFocus={autoFocus ? true : false}
          placeholder={content.emailPlaceholder[language]}
          title={content.emailTitle[language]}
          required
          autoComplete="email"
        />
        <input
          type="text"
          name="webaddress"
          onChange={handleChange}
          noValidate
          placeholder={content.websitePlaceholder[language]}
          title={content.websiteTitle[language]}
          autoComplete="new address"
        />
        <textarea
          className="textarea"
          name="message"
          placeholder={content.messagePlaceholder[language]}
          onChange={handleChange}
          required
        />
        {/* Spinner */}


        {/* Submit button, disabled when isLoading */}
        <button type="submit" disabled={isLoading}>{isLoading ? 'Sending...' : content.buttonText[language]}</button>
        {/* Error message for email validation */}
        <div className="error">
          {errors.email && formData.email && formData.email.length > 0 ? "Email is invalid" : null}
        </div>
        {/* Response message or error from sending email */}
        {!isLoading && <div className="response">{errors.response}</div>}
      </form>
    </StyledFormWrapper>

  );
};

export default ContactForm;